import React, { Component } from 'react'
import SEO from '../components/seo'
import { Flex, Box } from '@rebass/grid'

import Title from '../components/page-title'
import Copy from '../components/page-copy'
import Row from '../components/page-content-row'

import Image from '../components/page-image-new'

import PageButtons from '../components/page-buttons'
import { theme } from '../util/style'
import { Link } from 'gatsby'

const AboutImage1 = 'etc/about-us-1.jpg'
const AboutImage2 = 'etc/about-us-2.jpg'

import Image1 from '../images/etc/HH-portrait-tmp.jpg'
import Image2 from '../images/etc/HH-LG-Banyo-3289-rgb.jpg'

const ogImages = [
  `https://happyhaus.com.au${Image1}`,
  `https://happyhaus.com.au${Image2}`,
]

class Page extends Component {
  render() {
    return (
      <>
        <SEO
          title="About"
          description=" A Happy Haus is beautiful yet practical. It is a home that
                engages with its surrounds, and maximises the ability to enjoy
                the benefits of life in the subtropics."
          image={ogImages}
        />

        <Row>
          <Flex flexWrap={['wrap', 'nowrap']}>
            <Box width={[1, 6 / 12]} px={2} pr={[0, 0]}>
              <Title grey>About us</Title>
            </Box>
            <Box width={[1, 6 / 12]} px={1} mr={[0, 0]}>
              <Title>
                Our in-haus design + build approach encompasses the best of
                architecture and construction.
              </Title>
            </Box>
          </Flex>
        </Row>

        <Row>
          <Flex flexWrap="wrap">
            <Box width={[1]} px={2} mr={[0, 0]}>
              <Image src={AboutImage1} alt="About Image" />
            </Box>
          </Flex>
        </Row>

        <Row>
          <Flex flexWrap={['wrap', 'nowrap']}>
            <Box width={[1, 6 / 12]} px={1} pb={[2, 3]} pr={[0, 0]}>
              <Copy grey>Considered living by Happy Haus</Copy>
            </Box>
            <Box width={[1, 6 / 12]} px={1} mr={[0, 0]}>
              <Copy>
                A Happy Haus is a home that connects to its surroundings and
                elevates how you live in the subtropics. Our approach
                encompasses the best of architecture and construction with an
                in-haus design + build team to guide you along the right path
                from haus to home. This is how we make a Happy Haus.
              </Copy>
            </Box>
          </Flex>
        </Row>

        <Row>
          <Flex flexWrap={['wrap', 'nowrap']}>
            <Box width={[1, 6 / 12]} px={1} pb={[2, 3]} pr={[0, 0]}>
              <Copy grey>Good design for more Australians</Copy>
            </Box>
            <Box width={[1, 6 / 12]} px={1} mr={[0, 0]}>
              <Copy>
                We are guided by a response to your site and apply architectural
                principles to maximise light, breezes, privacy, and views. We
                open the door to good design for more Australians.
              </Copy>
            </Box>
          </Flex>
        </Row>

        <Row>
          <Flex flexWrap={['wrap', 'nowrap']}>
            <Box width={[1, 6 / 12]} px={1} pb={[2, 3]} pr={[0, 0]}>
              <Copy grey>Drawing from vast experience</Copy>
            </Box>
            <Box width={[1, 6 / 12]} px={1} mr={[0, 0]}>
              <Copy>
                We’re continually refining our Haus Series as starting points to
                be shaped into your home. Since 2009 we have had the pleasure of
                creating homes for many great clients in some of Australia's
                most amazing locations. Every architect-designed haus in our
                series draws from our vast experience.
              </Copy>
            </Box>
          </Flex>
        </Row>
        <Row>
          <Flex flexWrap={['wrap', 'nowrap']}>
            <Box width={[1, 6 / 12]} px={1} pb={[2, 3]} pr={[0, 0]}>
              <Copy grey>A focus on quality over quantity</Copy>
            </Box>
            <Box width={[1, 6 / 12]} px={1} mr={[0, 0]}>
              <Copy>
                We take on all kinds of projects; however, we limit the number
                of homes and where we build to maintain our quality and client
                experience. Our in-haus design + build experts will guide you
                through design, interiors, cost planning, development, and
                construction approvals, building documentation and construction.
              </Copy>
            </Box>
          </Flex>
        </Row>

        <Row>
          <Flex flexWrap={['wrap', 'nowrap']}>
            <Box width={[1, 6 / 12]} />
            <Box width={[1, 6 / 12]} px={1} mr={[0, 0]}>
              <Image src={AboutImage2} alt="About Image" />
              <Box mt={[2, 3]}>
                <Title>
                  If you’re considering a Happy Haus or about to embark on a new
                  haus project, we can’t wait to say, “welcome home!”
                </Title>
                <Title grey>Lachlan Grant & Rick Maklary</Title>
              </Box>
            </Box>
          </Flex>
        </Row>
      </>
    )
  }
}

export default Page
